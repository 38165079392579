
import { mapGetters } from 'vuex'

export default {
  name: 'CheckoutBar',
  components: {
    CartCheckoutPopup: () => import('@/components/CartCheckoutPopup'),
  },
  data() {
    return {
      showPaymentOptions: false,
    }
  },
  computed: {
    ...mapGetters('checkout', ['order']),
    ...mapGetters('cart', ['loading', 'total', 'items', 'totalPrice']),
    ...mapGetters('ui', ['isAddToBagFeedbackActive']),
  },

  mounted() {
    this.$nuxt.$on('payment-error', () => {
      this.showPaymentOptions = true
    })
  },
  methods: {
    checkout() {
      if (this.$auth.loggedIn) {
        this.$router.push('/checkout/info')
      } else {
        this.$router.push('/checkout/guest')
      }

      this.$store.dispatch('ui/closeAll')
    },
    continueToShopping() {
      this.$store.dispatch('ui/setAddToBagFeedback', false)
    },
    checkoutButton() {
      const safari =
        navigator.userAgent.search('Safari') >= 0 &&
        navigator.userAgent.search('Chrome') < 0
      if (
        +this.totalPrice >= process.env.EXPRESS_PAYMENT_THRESHOLD &&
        !safari
      ) {
        this.checkout()
      } else {
        this.showPaymentOptions = this.items.length > 0
      }
    },
  },
}
