import { render, staticRenderFns } from "./CheckoutBar.vue?vue&type=template&id=291655f3&scoped=true"
import script from "./CheckoutBar.vue?vue&type=script&lang=js"
export * from "./CheckoutBar.vue?vue&type=script&lang=js"
import style0 from "./CheckoutBar.vue?vue&type=style&index=0&id=291655f3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "291655f3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartCheckoutPopup: require('/opt/atlassian/pipelines/agent/build/components/CartCheckoutPopup.vue').default,Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default,Button: require('/opt/atlassian/pipelines/agent/build/components/Button.vue').default})
