
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    mobile: { type: Boolean, default: false },
  },
  data() {
    return {
      search: {
        active: false,
        query: '',
      },
      lastQuery: '',
      searchSuggestions: [],
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.hasResultAndActive && this.mobile ? 'overflow-hidden' : '',
      },
    }
  },
  computed: {
    ...mapGetters('search', ['recentSearches']),
    hasResultAndActive() {
      return this.searchSuggestions.length && this.search.active
    },
  },
  mounted() {
    document.addEventListener('keydown', this.searchShortcutListener)
  },
  destroyed() {
    document.removeEventListener('keydown', this.searchShortcutListener, false)
  },
  methods: {
    ...mapActions('search', ['clear']),
    onSubmitSearch() {
      if (this.search.query !== '') {
        this.onSearch(this.search.query)
      }
    },
    onSearchQueryChange(value) {
      if (this.lastQuery === value) {
        return
      }
      this.lastQuery = value

      if (!value.length) {
        this.searchSuggestions = []
        return
      }

      this.$axios
        .$get('/search/suggestion', { params: { query: value } })
        .then((response) => {
          this.searchSuggestions = response.data
        })
        .catch((e) => {
          this.searchSuggestions = []
        })
    },
    onSearch(query) {
      // Clear search suggestions
      this.searchSuggestions = []
      if (query !== '') {
        localStorage.setItem('lastScrollPage', 1)
        localStorage.setItem('lastScrollPosition', 0)
        sessionStorage.removeItem('lastCollectionData')
        // recent searches
        this.$store.dispatch('search/add', query)

        if (this.$route.path === '/products') {
          this.$root.$emit('search', query)
        }
        this.$router.push(
          this.localePath({ name: 'products', query: { q: query } })
        )

        this.deactivateSearch()
        this.search.query = ''
      }
    },
    onClickSearchButton() {
      this.activateSearch()
      if (this.search.active && this.search.query !== '') this.onSubmitSearch()
      else {
        this.activateSearch()
        this.$refs.searchInput.focus()
        const to = setTimeout(() => {
          this.$refs.searchInput.focus()
          clearTimeout(to)
        }, 100)
      }
    },
    handleDocumentClick(e) {
      if (!this.$refs.searchForm.contains(e.target)) {
        this.deactivateSearch()
      }
    },
    activateSearch(e) {
      if (e && e.key === 'Enter') this.onSubmitSearch()
      this.search.active = true
      if (document.querySelector('.suggestions'))
        document.querySelector('.suggestions').classList.remove('hide')
      // setTimeout(() => {
      //   document.addEventListener('click', this.handleDocumentClick)
      // }, 200)
    },
    deactivateSearch() {
      this.search.active = false
      this.search.query = ''
      this.searchSuggestions = []
      this.$emit('close')
      document.removeEventListener('click', this.handleDocumentClick)
    },
    onEscapeInput() {
      this.deactivateSearch()
    },
    searchShortcutListener(e) {
      if (e.key === 'f' && e.metaKey) {
        e.preventDefault()
        this.$refs.searchInput.focus()
        this.activateSearch()
        return false
      }
    },
  },
}
