
import { mapGetters } from 'vuex'
import HeaderCartItem from './HeaderCartItem.vue'

export default {
  components: {
    HeaderCartItem,
  },
  data() {
    return {
      loading: true,
      baseFreeShippingLimit: parseFloat(process.env.BASE_FREE_SHIPPING_LIMIT),
    }
  },
  computed: {
    ...mapGetters('cart', ['lastAddedItem']),
  },
  methods: {
    checkoutButton() {
      if (this.$auth.loggedIn) {
        this.$router.push(this.localePath('/checkout/info'))
      } else {
        this.$router.push(this.localePath('/checkout/guest'))
      }
    },
  },
}
