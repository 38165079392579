
import { mapActions, mapGetters } from 'vuex'
import HeaderIconButton from '@/containers/header/HeaderIconButton'
import { assetURL } from '~/plugins/utilities'

export default {
  components: {
    HeaderIconButton,
    Logo: () => import('@/components/Logo.vue'),
    HeaderSearch: () => import('@/containers/header/HeaderSearch.vue'),
    CategoriesDropdown: () =>
      import('@/containers/theme/HomepageHeader/CategoriesDropdown.vue'),
    HeaderCategoriesMobile: () =>
      import(
        '@/containers/theme/HeaderCategoriesMobile/HeaderCategoriesMobile.vue'
      ),
    HeaderCartItemsDesktop: () =>
      import('@/containers/header/HeaderCartItems/HeaderCartItemsDesktop.vue'),
    HeaderCartItemsMobile: () =>
      import('@/containers/header/HeaderCartItems/HeaderCartItemsMobile.vue'),
  },
  data() {
    return {
      show: false,
      shows: {
        search: false,
        categories: false,
      },
    }
  },
  head() {
    return {
      bodyAttrs: {
        class: this.isSidebarActive ? 'overflow-hidden' : '',
      },
      htmlAttrs: {
        class: this.isSidebarActive ? 'overflow-hidden' : '',
      },
    }
  },
  computed: {
    ...mapGetters('cart', ['total']),
    ...mapGetters('ui', ['isCartActive']),
    isHomePage() {
      return this.$route.fullPath === '/'
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.doHideSider('cart')
        this.show = false
      })
    },
  },
  mounted() {
    this.$nuxt.$on('payment-error', (error) => {
      if (error === 'google' || error === 'apple') {
        if (window.innerWidth > 990) {
          this.doShowSider('cart')
          this.show = false
        }
      }
    })
  },
  methods: {
    ...mapActions('ui', ['doHideSider', 'doShowSider']),
    assetURL,
    openSearch() {
      this.shows.search = !this.shows.search
      if (this.shows.search) {
        this.shows.categories = false
      }
    },
  },
}
