
import { mapGetters } from 'vuex'
import HeaderCartItem from './HeaderCartItem.vue'

export default {
  components: {
    HeaderCartItem,
  },
  data() {
    return {
      loading: true,
      baseFreeShippingLimit: parseFloat(process.env.BASE_FREE_SHIPPING_LIMIT),
    }
  },
  computed: {
    ...mapGetters('cart', ['total', 'totalPrice', 'orderDataV']),
    selectedShippingPrice() {
      return this.$options.filters.money(
        this.$store.state.cart.order.selectedShipping.price
      )
    },
    eligibleFreeShippingCalculation() {
      if (this.orderDataV) {
        const gift = this.orderDataV.currency_gift_card_amount || 0
        const ship = this.orderDataV.currency_shipping_total || 0

        return this.baseFreeShippingLimit - (this.totalPrice + gift - ship)
      } else {
        return this.baseFreeShippingLimit
      }
    },
  },
  methods: {
    checkoutButton() {
      if (this.$auth.loggedIn) {
        this.$router.push(this.localePath('/checkout/info'))
      } else {
        this.$router.push(this.localePath('/checkout/guest'))
      }
    },
  },
}
